import { Link } from "react-router-dom";
import { Avatar as AvatarNew } from 'components/avatar';
import { PremiumBadge } from "components/Spark/Spark";
import { ROLES } from "utils/data/Constants";

function Avatar({ author }) {
  return (
      <div className="flex items-center">
          <AvatarNew src={author?.img?.url} alt="" className="w-6 h-6 rounded-full mr-2" />
          <span className="font-semibold text-xs text-gray-500">
              {author?.name}
          </span>
          {author?.roles?.includes(ROLES.Premium) && (
            <PremiumBadge />
          )}
      </div>
  );
}

export default function FullNameAvatar({ author, isLink = true }) {
  return isLink ? (
      <Link to={"/user/" + author?.username}>
          <Avatar author={author} />
      </Link>
  ) : (
      <Avatar author={author} />
  );
}