import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { usePlatform } from 'utils/PlatformProvider';

import QuiziniLogoWhite from "assets/img/Quizini-Logo-white.svg";
import LegendiniLogoWhite from "assets/img/Legendini-white.svg";

const PlatformTransition = () => {
  const { currentPlatform } = usePlatform();
  const [isAnimating, setIsAnimating] = useState(false);
  const previousPlatform = useRef(currentPlatform); // Track the previous platform

  useEffect(() => {
    if (currentPlatform && currentPlatform !== previousPlatform.current) {
      setIsAnimating(true);
      previousPlatform.current = currentPlatform; // Update previous platform
      setTimeout(() => setIsAnimating(false), 600);
    }
  }, [currentPlatform]);

  const wipeVariants = {
    initial: {
      x: '-100%',
    },
    animate: {
      x: 0,
      transition: {
        duration: 0.5,
        ease: 'circOut',
      },
    },
    exit: {
      x: '100%',
      transition: {
        duration: 0.5,
        ease: 'circIn',
      },
    },
  };

  return (
    <AnimatePresence>
      {isAnimating && (
        <motion.div
          key="transition-background"
          className="fixed inset-0 z-50 flex items-center justify-center"
          variants={wipeVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{
            backgroundImage: currentPlatform === 'legendini'
              ? 'linear-gradient(120deg, #19CB1C, #0EDD78, #09D8F5)'
              : 'linear-gradient(120deg, #8A41FE, #8E36FE, #6283FF, #33ABFF)',
            transformOrigin: 'left',
          }}
        >
          <div
            className="w-56 flex items-center justify-center"
          >
            {currentPlatform === 'legendini' ? (
              <img
                src={LegendiniLogoWhite}
                alt="Legendini"
                className="w-full h-full object-contain"
              />
            ) : (
              <img
                src={QuiziniLogoWhite}
                alt="Quizini"
                className="w-full h-full object-contain"
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PlatformTransition;
