import { Outlet } from 'react-router-dom';

// components
import Footer from 'components/Footer/Footer.js';
import { Navbar, NavbarSection } from 'components/navbar';
import { Link } from 'react-router-dom';
import { Heading } from 'components/heading';
import Logo from "assets/img/Quizini-Logo.svg";
import { Container } from 'components/Container/Container';

export default function SimpleLayout() {
  return (
    <>
      <Container className="bg-white">
        <Navbar>
          <NavbarSection>
            <Link to="/">
              <img className="h-8 ml-1 w-auto" src={Logo} alt="Your Company" />
            </Link>
            <Heading id="navbar-title"></Heading>
          </NavbarSection>
        </Navbar>
        <Outlet />
        <Footer />
      </Container>
    </>
  );
}
