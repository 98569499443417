import React, { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { ROLES } from "./data/Constants";

const AdsComponent = ({dataAdSlot, dataAdFormat = "auto", style = {}, isFullWidth = "true", classNames }) => {
  const { auth } = useAuth();
  const mergedStyles = { display: "block", ...style };

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  if (auth?.user?.roles?.includes(ROLES.Premium)) {
    return null;
  }


  // do not render ads if user is localhost
  if (window.location.hostname === "localhost") {
    return <div>Ad not loaded in localhost</div>;
  }

  return (
    <>
      <ins
        className={"adsbygoogle" + (classNames ? " " + classNames : "")}
        style={mergedStyles}
        data-ad-client="ca-pub-9400409503461204"
        data-ad-slot={dataAdSlot}
        data-ad-format={dataAdFormat}
        data-full-width-responsive={isFullWidth}
      ></ins>
    </>
  );
};

export function VerticalAdUnit(){
  return (
    <div className="font-light text-gray-500 text-xs p-1">
      <div className="mx-2 min-h-[60px] w-full">
        <AdsComponent
          style={{ display: 'block' }}
          dataAdFormat="auto"
          isFullWidth="true"
          dataAdSlot="8882996600" // Responsive Block
        />
      </div>
    </div>
  );
}

export default AdsComponent;
