import { Dialog, DialogBody, DialogTitle } from 'components/dialog'

export default function Modal({ open, setOpen, title, children }) {
    return (
      <>
      <Dialog open={open} onClose={setOpen}>
        {title && (
          <DialogTitle>{title}</DialogTitle>
        )}
        <DialogBody>
          {children}
        </DialogBody>
      </Dialog>
      </>
    );
  }