import Button, { ButtonProps } from "./Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      // className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      className={`ButtonPrimary text-neutral-50 cursor-pointer ${className}`}
      {...args}
    />
  );
};

export const ButtonPrimaryLegendini: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ButtonPrimaryLegendini text-neutral-50 cursor-pointer ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;

