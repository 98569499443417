// import { avatarColors } from "contains/contants";
import React, { FC } from "react";
import clsx from "clsx";
import { usePlatform } from "utils/PlatformProvider";

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  levelClass?: string;
  level?: string;
  progress?: number;
  isHeader?: boolean;
  membership?: string;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl = 'https://via.placeholder.com/150',
  userName,
  level,
  isHeader = false,
  progress,
  levelClass = "w-4 h-4 bottom-1 -right-0.5",
  membership
}) => {
  const url = imgUrl || "";
  const name = userName || "";
  const {currentPlatform} = usePlatform();

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
    >
      <div className="bg-red-500 ">
        {url && (
          <img
            className={`absolute inset-0 w-full h-full object-cover ${radius}`}
            src={url}
            alt={name}
          />
        )}
        <span className="wil-avatar__name">{name[0]}</span>

        {progress && (
          <div className="absolute top-0 right-3 w-full h-full -mt-2 -mr-1">
            <ProgressRing
              radius={ 28 }
              stroke={ 4 }
              progress={ progress }
              currentPlatform={currentPlatform}
            />
          </div>
        )}
        {level && (
          <div className={clsx(
            `text-white text-xs text-center ring-1 ring-white font-bold absolute flex items-center justify-center -mb-1 right-0 bottom-0 rounded-full w-4 h-4 ${levelClass}`,
            membership === "spark" ? ( currentPlatform === 'quizini' ? "bg-gradient-to-tr from-fuchsia-500 via-pink-600 to-purple-600" : 'bg-gradient-to-tr from-emerald-500 via-teal-600 to-green-700') : "bg-primary-800",
          )}>
              {!isHeader ? (
                <span className="hidden sm:block">Level &nbsp;</span>
              ) : null} {level}
          </div>
        )}
      </div>
    </div>
  );
};

function ProgressRing({ radius, stroke, progress, currentPlatform }) {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      >
      <circle
        stroke={currentPlatform === 'quizini' ? "#9333ea": "#10b981"}
        fill="transparent"
        strokeWidth={ stroke }
        strokeLinecap="round"
        strokeDasharray={ circumference + ' ' + circumference }
        style={ { strokeDashoffset } }
        r={ normalizedRadius }
        cx={ radius }
        cy={ radius }
        />
    </svg>
  );
}

export default Avatar;
