import { useEffect, useState } from "react";

export default function SmallStreakIndicator({ userStreak }) {
  const [completed, setCompleted] = useState(false);
  const [streak, setStreak] = useState(0);

  useEffect(() => {
    if (userStreak && userStreak.dates.length > 0) {
      const now = new Date();
      const todayUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      const lastEntryDate = new Date(userStreak.dates[userStreak.dates.length - 1]);
      const lastEntryUTC = new Date(Date.UTC(lastEntryDate.getUTCFullYear(), lastEntryDate.getUTCMonth(), lastEntryDate.getUTCDate()));

      // Check if the last entry is today (UTC)
      if (todayUTC.getTime() === lastEntryUTC.getTime()) {
        setCompleted(true);
        setStreak(userStreak.current);
      } else {
        setCompleted(false);
        // Check if the last entry was yesterday (UTC)
        const yesterdayUTC = new Date(todayUTC);
        yesterdayUTC.setUTCDate(yesterdayUTC.getUTCDate() - 1);
        if (lastEntryUTC.getTime() === yesterdayUTC.getTime()) {
          setStreak(userStreak.current - 1); // Yesterday's streak
        } else {
          setStreak(0); // Streak broken
        }
      }
    }
  }, [userStreak]);

  const flameColor = completed ? '#ff773a' : '#a7a7a7';

  return (
    <div
      className={'flex w-6 h-6 rounded-lg '}
    >
      <div className="w-0 flex-1 -mt-1 -ml-1">
        <div className="block items-center">
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto"
              viewBox="0 0 18.131 19.767"
            >
              <g
                id="svgexport-14_1_"
                data-name="svgexport-14 (1)"
                transform="translate(-2.878 -1.983)"
              >
                <path
                  id="Pfad_171"
                  data-name="Pfad 171"
                  d="M15.362,5.214A8.251,8.251,0,1,1,6.038,7.048,8.287,8.287,0,0,0,9,9.6a8.983,8.983,0,0,1,3.361-6.867,8.21,8.21,0,0,0,3,2.48Z"
                  fill={flameColor}
                  stroke={flameColor}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
            <div className="mt-1.5 text-center absolute left-0 right-0 mx-auto top-0 w-8">
              <span className="text-white font-bold">{streak}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}