import React from "react";
import logo from "assets/img/quizini-logo.png";
import { Link } from "react-router-dom";
import SocialIcons from "components/Social/SocialIcons";

const navigation = {
    main: [
      { name: 'Contact', href: '/contact' },
      { name: 'Cookie Policy', href: '/cookies' },
      { name: 'Terms & Conditions', href: '/terms' },
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'Imprint', href: '/imprint' },
    ]
  }
  
  export default function Footer() {
    return (
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden py-4 pb-16 px-6 lg:px-8">
          <nav className="-mb-6 flex justify-center gap-4 sm:space-x-12" aria-label="Footer">
            {navigation.main.map((item) => (
              <div key={item.name} className="">
                <Link to={item.href} className="text-xs sm:text-sm leading-6 text-gray-600 hover:text-gray-900">
                  {item.name}
                </Link>
              </div>
            ))}
          </nav>
          <div className="mt-10 flex justify-center space-x-10">
            <SocialIcons />
          </div>
          <p className="mt-4 text-center text-xs leading-5 text-gray-500">
            © {new Date().getFullYear()} <a href="https://www.quizini.com" className="hover:underline">Quizini</a>. All Rights Reserved.
          </p>
        </div>
      </footer>
    )
  }