import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        // auth?.user?.role === allowedRoles?.includes(2)
        // auth?.user?.roles?.find(roles => allowedRoles?.includes('admin'))
        // auth?.user?.role >= allowedRoles

        auth?.user?.roles?.some(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth.user === null
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;