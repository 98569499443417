import { DataInteractive as HeadlessDataInteractive } from '@headlessui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const Link = React.forwardRef(function Link({ href, ...props }, ref) {
  return (
    <HeadlessDataInteractive>
      <RouterLink to={href} {...props} ref={ref} />
    </HeadlessDataInteractive>
  )
})
