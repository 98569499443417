import { useState, useEffect } from 'react';

const DeviceType = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
};

function useDeviceType() {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  function getDeviceType() {
    if (typeof window === 'undefined') return DeviceType.DESKTOP;  // Default to desktop on SSR
    if (window.innerWidth < 768) {
      return DeviceType.MOBILE;
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      return DeviceType.TABLET;
    } else {
      return DeviceType.DESKTOP;
    }
  }

  useEffect(() => {
    let timeoutId;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setDeviceType(getDeviceType());
      }, 150);  // debounce time
    };

    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return deviceType;
}

export default useDeviceType;
export { DeviceType };
