import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useConversations } from "utils/ConversationsProvider";
import useAuth from "utils/hooks/useAuth";
import { useSocket } from "utils/SocketProvider";

export default function HandleUserEvents(){
  const socket = useSocket();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { updateConversationWithNewMessage } = useConversations();

  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (message) => {
      const currentUrl = window.location.pathname;
      const messageUrl = `/messages/${message.conversation._id}`;
      const isRead = currentUrl === messageUrl;
      const lastMessageReadBy = isRead ? [auth.user._id] : [];

      console.log();
      updateConversationWithNewMessage(message.conversation._id, message, lastMessageReadBy);
      if (isRead) {
        return;
      }
      if (auth.user.disabled_notifications.includes('messages_popup')) {
        return;
      }
      toast(
        <MessageBody 
          message={message}
          navigate={navigate}
        />,
        {
          position: 'top-right',
          duration: 5000,
        }
      );
    };

    socket.on('new-message', handleNewMessage);

    return () => {
      socket.off('new-message', handleNewMessage);
    };
  }, [socket]);

  return(
    <></>
  );
}


function MessageBody({message, navigate}){
  return(
    <button
      className="rounded-md w-full cursor-pointer text-left"
      onClick={() => navigate(`/messages/${message.conversation._id}`)}
    >
      <div className="flex w-full">
        <div className="flex-shrink-0">
          {/* <EnvelopeIcon aria-hidden="true" className="h-6 w-6 text-purple-800" /> */}
          <img src={message.author?.img.url} alt="Profile" className="h-10 w-10 rounded-full object-cover" />
        </div>
        <div className="ml-3 w-full">
          <h3 className="text-sm font-medium text-gray-900">
            {message.author?.name ? message.author.name : message.author.username}
          </h3>
          <div className="text-sm text-gray-700 w-full line-clamp-2">
            <p>
              {message.text}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}