
export default function Loader({ size = "lg" }) {
    let loaderSize = "h-screen py-16";
    if (size === "sm") {
        loaderSize = "py-2";
    }

    return (
        <section className="relative">
            <div className={"container mx-auto px-4 "+loaderSize}>
                <h3 className="text-4xl text-center flex justify-center font-semibold leading-normal text-blueGray-700 mb-2">
                    <div className="spinner-3"></div>
                </h3>
            </div>
        </section>
    );
}