import QLogo from "assets/img/Q-logo.svg";
import LLogo from "assets/img/L-logo.svg";
import { usePlatform } from "utils/PlatformProvider";
import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import { Button } from "@headlessui/react";
import { TouchTarget } from "components/button";

export default function PlatformLogoToggle() {
  const { currentPlatform, switchPlatform } = usePlatform();

  if (currentPlatform === 'quizini') {
    return (
      <SwitcherToLegendini 
        onClick={() => switchPlatform('legendini')}
      />
    );
  }
  return (
    <SwitcherToQuizini 
      onClick={() => switchPlatform('quizini')}
    />
  );
}

const SwitcherToQuizini = ({ onClick }) => {
  return (
  <Button
    onClick={onClick}
    className="overflow-hidden hover:shadow-md mr-3 lg:mr-0 -mt-1 p-1.5 w-9 h-9  bg-white border-2 border-green-800 hover:border-purple-800 text-green-800 rounded-md text-xl font-bold cursor-pointer relative z-10 group"
  >
    <TouchTarget>
    <ArrowsRightLeftIcon />
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"
    ></span>
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-purple-400 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"
    ></span>
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-purple-50 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-left"
    ></span>
    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-1 left-1 z-10">
      <img src={QLogo} className="w-6 h-6" alt="Quizini Logo" />
    </span>
    </TouchTarget>
  </Button>
  );
}

const SwitcherToLegendini = ({ onClick }) => {
  return (
  <Button
    onClick={onClick}
    className="overflow-hidden hover:shadow-md mr-3 lg:mr-0 -mt-1 p-1.5 w-9 h-9  bg-white border-2 border-purple-800 hover:border-orange-600 text-purple-800 rounded-md text-xl font-bold cursor-pointer relative z-10 group"
  >
    <TouchTarget>
    <ArrowsRightLeftIcon />
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"
    ></span>
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-orange-300 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"
    ></span>
    <span
      className="absolute w-36 h-32 -top-8 -left-2 bg-orange-100 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-left"
    ></span>
    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-1 left-1 z-10">
      <img src={LLogo} className="w-6 h-6" alt="Legendini Logo" />
    </span>
    </TouchTarget>
  </Button>
  );
}
