import useProgress from 'utils/hooks/useProgress';
import { usePlatform } from 'utils/PlatformProvider';

export default function Progressbar() {
  const { progress } = useProgress();
  const { currentPlatform } = usePlatform();

  return (
    <>
      <div className="flex justify-between items-end">
        <span className="text-sm font-medium text-primary-700">level {progress?.level}</span>
        <span className="text-xs lg:text-xs font-medium text-gray-500">{progress?.points} / {progress?.progress?.nextLevelPoints}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className={
            "bg-gradient-to-l h-2.5 rounded-full progress "
            + (currentPlatform === 'quizini' ? " from-purple-900 via-purple-700 to-fuchsia-700" : "from-green-800 via-green-700 to-emerald-500")
          }
          style={{ width: progress?.progress?.currentProgress + "%" }}>
          </div>
      </div>
    </>
  );
}
