
export function formatDateAndTime(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const timeOptions = { hour: 'numeric', minute: 'numeric' };

  if (inputDate.toDateString() === currentDate.toDateString()) {
    return inputDate.toLocaleTimeString('en-US', timeOptions);
  } else if (inputDate.toDateString() === currentDate.toDateString() - 1) {
    return 'Yesterday, ' + inputDate.toLocaleTimeString('en-US', timeOptions);
  } else {
    return inputDate.toLocaleDateString('en-US', dateOptions) + ', ' + inputDate.toLocaleTimeString('en-US', timeOptions);
  }
}

export function formatRelativeDate(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  
  // Calculate the time difference between the current date and the input date
  const diff = Math.abs(currentDate - inputDate);
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  
  // If it was less than 1 minute ago, return "Now"
  if (diffMinutes < 1) {
    return "Now";
  }
  // If it was less than 1 day ago, return the number of hours or minutes ago
  else if (diffHours < 24) {
    if (diffHours >= 1) {
      return `${diffHours}h`;
    } else {
      return `${diffMinutes}m`;
    }
  }
  // If it was this year, return the abbreviated month and day
  else if (inputDate.getFullYear() === currentDate.getFullYear()) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = inputDate.getMonth();
    const day = inputDate.getDate();
    return `${monthNames[monthIndex]} ${day}`;
  }
  // If it was last year or earlier, return the full date
  else {
    const year = inputDate.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = inputDate.getMonth();
    const day = inputDate.getDate();
    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }
}

export function formatDayMonthYear(inputDate) {
  const date = new Date(inputDate);
  
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = date.getUTCFullYear();
  
  return `${day}.${month}.${year}`;
}

export function formatMonthDayYearText(inputDate) {
  const date = new Date(inputDate);
  
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export function formatMonthYearDate(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const dateObj = new Date(date);
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  return `${month} ${year}`;
}