
// Array of all levels and their points exported
const levels = [
    {
        level: 1,
        points: 0
    },
    {
        level: 2,
        points: 100
    },
    {
        level: 3,
        points: 300
    },
    {
        level: 4,
        points: 800
    },
    {
        level: 5,
        points: 1500
    },
    {
        level: 6,
        points: 4500
    },
    {
        level: 7,
        points: 10000
    },
    {
        level: 8,
        points: 22000
    },
    {
        level: 9,
        points: 40000
    },
    {
        level: 10,
        points: 100000
    }
];

export default levels;