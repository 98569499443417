import { Button } from 'components/button';
import premiumIcon from 'assets/img/premium-icon.svg';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

export function ProBanner() {
  return (
    <div className="w-full h-48 rounded-xl bg-gradient-to-tr from-fuchsia-500 via-pink-600 to-purple-600 p-1">
      <div className="w-full h-full rounded-lg bg-white p-4 flex flex-col justify-between relative">
        {/* <div className="absolute top-0 right-0 -mt-4 -mr-4 bg-gradient-to-tr from-fuchsia-500 via-pink-600 to-purple-600 w-16 h-16 rounded-full" /> */}
        <img
          alt="Premium Icon"
          src={premiumIcon}
          className="absolute top-2 right-0 w-20 h-20 rotate-12"
        />
        <div>
          <p className="text-xl font-bold text-gray-800 mb-2">
            Try Quizini
            <SparkText>
              Spark
            </SparkText>
          </p>
          <p className="text-gray-700 text-base">
            Get your spark badge,<br /> no ads and more.
            {/* Get access to premium features<br /> and support Quizini. */}
          </p>
        </div>
        <Button href="/spark" className="w-full" color="quizini">
          Try 7 Days Free
        </Button>
      </div>
    </div>
  );
}

export function ProBannerSmall() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/spark')}
      className="group w-full rounded-full bg-gradient-to-tr from-fuchsia-500 via-pink-600 to-purple-600 p-0.5">
      <div className="group-hover:bg-white group-hover:shadow-md group-hover:shadow-fuchsia-500  w-full h-full rounded-full bg-zinc-50 px-4 flex justify-between items-center relative">
        <div>
          <p className="text-lg font-bold text-gray-700">
            Quizini
            <SparkText>
              Spark
            </SparkText>
          </p>
        </div>
        <ArrowRightIcon className="h-5 w-5" />
      </div>
    </button>
  );
}

export function SparkText({children, className}) {
  return (
    <span className={clsx(className, "mx-2 text-transparent italic bg-clip-text bg-gradient-to-bl from-fuchsia-500 via-pink-600 to-purple-600")}>
      {children}
    </span>
  )
}

export function PremiumBadge({className, text, size}) {
  let sizeClass;
  switch (size) {
    case "lg":
      sizeClass = "px-1 text-[11px] mx-1";
      break;  
    default:
      sizeClass = "px-1 text-[11px] mx-1 h-3.5";
  }

  return (
    <div className={clsx(className, sizeClass, "bg-gradient-to-tr from-fuchsia-500 via-pink-600 to-purple-600 rounded-full text-white font-semibold flex items-center")}>
      {text ? text : "Spark"}
    </div>
  );
}
