import React, { createContext, useState, useCallback } from "react";
import Modal from "./Modal";

const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
  
    const openModal = useCallback((content, title) => {
      setModalContent(content);
      setModalTitle(title);
      setModalOpen(true);
    }, []);
  
    const closeModal = useCallback(() => {
      setModalOpen(false);
      setModalContent(null);
    }, []);

    return (
        <ModalContext.Provider value={{ modalOpen, modalTitle, openModal, closeModal }}>
          {children}
          {modalOpen && 
            <Modal
                open={modalOpen}
                setOpen={closeModal}
                title={modalTitle}
            >
                {modalContent}
            </Modal>
        }
        </ModalContext.Provider>
      );
}

export default ModalContext;