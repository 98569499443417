const ROLES = {
  User: 'free',
  Admin: 'admin',
  Premium: 'spark',
  Moderator: 'mod',
  Tester: 'tester',
};

module.exports = {
  ROLES,
};