import { Link } from 'react-router-dom';
import defaultprofile from '../../assets/img/defaultprofile.jpg';
import Image from '../../components/Image/Image';
import classNames from 'utils/classNames';
import { formatRelativeDate } from 'utils/DateFormatter';
import { PremiumBadge } from 'components/Spark/Spark';
import { ROLES } from 'utils/data/Constants';

export default function UserDisplay({
  user,
  type,
  size = 'md',
  onClick,
  timeAgo,
}) {
  // if (!user) {
  //   return null;
  // }

  if (!onClick) {
    return (
      <Link to={'/user/' + user?.username} className="flex-1 min-w-0 w-12">
        <UserInner type={type} user={user} size={size} timeAgo={timeAgo} />
      </Link>
    );
  } else {
    return (
      <div
        onClick={onClick}
        className="lg:mr-4 w-full hover:bg-gray-100  items-center flex"
      >
        <UserInner user={user} size={size} />
      </div>
    );
  }
}

function UserInner({ user, type, size, timeAgo }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0">
        <Image
          className={classNames(
            'rounded-full object-cover ',
            size === 'sm' ? 'h-8 w-8' : 'h-10 w-10'
          )}
          src={user?.img ? user?.img?.url : defaultprofile}
          alt=""
        />
      </div>
      <div className={'ml-2 truncate ' + (timeAgo ? ' ' : '')}>
        <div className={'text-sm font-bold text-gray-600 flex items-center'}>
          {user?.name ? user?.name : user?.username}
          {user?.roles?.includes(ROLES.Premium) && (
            <PremiumBadge />
          )}
        </div>
        {timeAgo ? (
          <div className="text-xs text-gray-400">
            {formatRelativeDate(timeAgo)}
          </div>
        ) : null}
        {type === 'bio' ? (
          <div className="text-sm text-gray-400 truncate">{user.bio}</div>
        ) : null}
        {type === 'username' ? (
          <div className="text-sm text-gray-400 truncate">
            @{user?.username}
          </div>
        ) : null}
      </div>
    </div>
  );
}
