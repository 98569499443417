import ListTags from 'components/Quiz/ListTags';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SimilarUsers from './SimilarUsers';
import { VerticalAdUnit } from 'utils/AdsComponent';
import useDeviceType from "utils/hooks/useDeviceType.js";
import { ProBanner } from 'components/Spark/Spark';
import useAuth from 'utils/hooks/useAuth';
import { ROLES } from 'utils/data/Constants';
import { usePlatform } from 'utils/PlatformProvider';


export default function SidebarRight({ tags }) {
  const deviceType = useDeviceType();
  const {auth} = useAuth();

  if(deviceType === 'mobile') {
    return null
  };

  return (
    <div className="w-80 h-full p-4 sticky top-0 shrink-0 hidden md:block">
      <SearchButton />
      {!auth?.user?.roles.includes(ROLES.Premium) && (
        <ProBanner />
      )}
      {tags && tags.length > 0 && (
        <div className="mx-auto max-w-2xl text-base leading-7 text-gray-700 px-2 border-b mt-4 border-none">
          <h3 className="text-lg font-bold pb-2">Discover popular topics</h3>
          <ListTags tags={tags} size="lg" />
        </div>
      )}
      {auth?.user && (
        <div className="mx-auto max-w-2xl text-base leading-7 text-gray-700 px-2 border-b mt-4 border-none">
          <h3 className="text-lg font-bold pb-2">You might like</h3>
          <SimilarUsers />
        </div>
      )}
      <VerticalAdUnit />
    </div>
  );
}

export function SearchButton() {
  const { currentPlatform } = usePlatform();
  const searchUrl = currentPlatform === 'quizini' ? '/search' : '/stories/search';
  return (
    <div className="mb-4 w-full">
      <Link to={searchUrl} className="relative w-56">
        <div className="flex absolute left-0 mt-2.5 pl-3 pointer-events-none">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <button
          className="text-left text-gray-500 bg-gray-100 whitespace-nowrap hover:border-purple-700  placeholder-gray-300 flex border-0 text-sm rounded-full w-full pl-10 p-2.5"
        >
          Search
        </button>
      </Link>
    </div>
  );
}