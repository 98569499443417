import { Dialog, Transition } from '@headlessui/react';
import { useEffect, useState, Fragment } from "react";
import LevelUpBadge from "../../assets/img/LevelUpBadge.svg"; 
import useProgress from 'utils/hooks/useProgress';

export default function LevelUpModal({open, setOpen, level}){
    const [progressFiller, setProgressFiller] = useState(0);
    const {closeLevelInfo, progress} = useProgress();

    function closeHandler(){
        setOpen(false);
        closeLevelInfo();
        setProgressFiller(0);
    }

    useEffect(() => {
        if (open){
            setProgressFiller(0);
            setProgressFiller(100);
        }
    }, [open]);

    return(
    <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={closeHandler}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed z-20 inset-0 overflow-y-auto">
                    <div className="flex items-start justify-center min-h-screen pt-24 px-4 pb-20 text-center sm:block sm:p-0">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg bg-white">
                                    <div className="p-2">
                                        <div className="text-center">                                            
                                            <div className="mb-4">
                                                <div className="relative w-80 mx-auto -mt-28">
                                                    <img src={LevelUpBadge} className="drop-shadow-2xl" alt="Level Up Badge" />
                                                    <span className="absolute mt-6 font-bold text-white inset-x-0 top-0">
                                                        <span className="text-md ml-1">Level</span><br/>
                                                        <span className="text-4xl">
                                                            {level - 1}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <Dialog.Title
                                                as="h3"
                                                className="mb-6 sm:text-3xl font-bold leading-6 text-purple-900"
                                            >
                                                <span className="">Congrats,</span><br/>
                                                you leveled up!
                                            </Dialog.Title>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-base font-medium text-purple-700 dark:text-white">Level {level - 1} complete</span>
                                                <span className="text-sm font-medium text-purple-700 dark:text-white">{progress?.points} / {progress?.progress?.userLevelPoints}</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                <div className="bg-gradient-to-l from-purple-900 via-purple-700 to-fuchsia-700 h-2.5 rounded-full progress" style={{width: progressFiller+"%"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
    );
}
