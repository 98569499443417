import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import { io } from 'socket.io-client';
import useAuth from './hooks/useAuth';
import { SOCKET_URL } from '../config';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { auth } = useAuth();
  const [socket, setSocket] = useState(null);
  const prevUserIdRef = useRef(null);

  useEffect(() => {
    if (!auth.user) {
      return;
    }

    if (prevUserIdRef.current !== auth.user._id) {
      const newSocket = io(SOCKET_URL, {
        auth: {
          token: 'Bearer ' + auth.token,
        },
      });

      setSocket(newSocket);
      prevUserIdRef.current = auth.user._id;

      return () => {
        newSocket.close();
      };
    }
  }, [auth]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};