import { Outlet } from "react-router-dom";
import Footer from "components/Footer/Footer.js";
import SidebarRight from "views/SidebarRight/Sidebar";

export default function SidebarLayout() {
  return (
    <div className="flex">
      <div className="max-w-3xl mx-auto w-full">
        <Outlet />
        <Footer />
      </div>
      <SidebarRight tags={[]} />
    </div>
  );
}