import useProgress from 'utils/hooks/useProgress';
import starSVG from "../../assets/img/star.svg";
import defaultprofile from "../../assets/img/defaultprofile.jpg";
import Avatar from "components/Avatar/Avatar";
import useAuth from 'utils/hooks/useAuth';
import clsx from 'clsx';


export default function ProgressAvatar({user, direction = "right", showPoints = true}) {
  const { progress } = useProgress();
  const { auth } = useAuth();

  if (!user) {
    user = auth?.user;
  }

  return(
    <div className={clsx(
      "rounded-full w-34 flex items-center flex-none ",
      (direction === 'left' ? 'flex-row-reverse' : ''),
      (showPoints ? 'bg-gray-100' : '')
    )}>
      {showPoints && (
        <>
          <img className="mx-2 w-6 h-6" src={starSVG} alt="star"></img>
          <div className="relative font-bold text-xl">
            <span>{progress.points}</span>
          </div>
        </>
      )}
      <Avatar
        sizeClass="w-10 h-10 rounded-full object-cover"
        imgUrl={user?.img?.url ? user.img.url : defaultprofile}
        alt="profile image"
        level={progress?.level}
        progress={progress?.progress?.currentProgress}
        isHeader={true}
      />
    </div>
  );
}