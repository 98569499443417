const config = {
  development: {
    API_URL: 'http://localhost:5100/',
    SOCKET_URL: 'http://localhost:5100/',
  },
  test: {
    API_URL: 'http://tstapi.quizini.com/',
    SOCKET_URL: 'http://tstapi.quizini.com/',
  },
  production: {
    API_URL: 'https://api.quizini.com/',
    SOCKET_URL: 'https://api.quizini.com/', // for now we use the same URL for API and socket
  }
};
// Set environment based on NODE_ENV or default to development
const ENV = process.env.NODE_ENV || 'development';
export const { API_URL, SOCKET_URL } = config[ENV];