import { useEffect, useState } from 'react';
import LevelUpModal from './LevelUpModal';
import useProgress from 'utils/hooks/useProgress';

export default function LevelUpModalWrapper(){
    const {progress} = useProgress();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (progress) {
            setOpen(progress?.isLevelUp);
        }
    }, [progress]);

    return(
        <>
            {progress?.isLevelUp && (
                <LevelUpModal
                    open={open}
                    setOpen={setOpen}
                    level={progress?.level}
                />
            )}
        </>
    );
}
