import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { legendiniRoutes, quiziniRoutes } from './data/PlatformRoutes';
import PlatformTransition from './PlatformTransition';
import { routeMappings, sharedRoutes } from "utils/data/PlatformRoutes";

const PlatformContext = createContext();

export function PlatformProvider({ children }) {
  const [currentPlatform, setCurrentPlatform] = useState(() =>
    localStorage.getItem('currentPlatform') || 'quizini'
  );
  const location = useLocation();
  const navigate = useNavigate();


  const isSharedRoute = (path) => {
    return sharedRoutes.some((route) => path.startsWith(route));
  };

  const navigateAfterPlatformSwitch = (targetPlatform) => {
    if (isSharedRoute(location.pathname)) {
      return;
    }

    // For platform-specific routes, find the corresponding route in the new platform
    const mappings = routeMappings[targetPlatform];
    const currentPath = location.pathname;
    const newPath =
      Object.entries(mappings).find(([from, to]) => currentPath === from)?.[1] ||
      (targetPlatform === "legendini" ? "/legendini" : "/");

    navigate(newPath);
  };

  const switchPlatform = (targetPlatform) => {
    if (currentPlatform === targetPlatform) return;

    localStorage.setItem('currentPlatform', targetPlatform);
    setCurrentPlatform(targetPlatform);
    updateTheme(targetPlatform);
    navigateAfterPlatformSwitch(targetPlatform);
  };

  const updateTheme = (platform) => {
    const themecolor = platform === 'quizini' ? 'purple' : 'green';
    if (!themecolor) return;
    document.documentElement.dataset.theme = themecolor;
  };

  const getPlatformForRoute = (path) => {
    if (isSharedRoute(path)) return null;
    
    const isQuiziniRoute = quiziniRoutes.some(route => path.startsWith(route));
    if (isQuiziniRoute) return 'quizini';
    
    const isLegendiniRoute = legendiniRoutes.some(route => path.startsWith(route));
    if (isLegendiniRoute) return 'legendini';
    
    // Default platform based on root path
    if (path === '/') return 'quizini';
    if (path === '/legendini') return 'legendini';
    
    return null;
  };

  useEffect(() => {
    const platformForCurrentRoute = getPlatformForRoute(location.pathname);

    if (platformForCurrentRoute && platformForCurrentRoute !== currentPlatform) {
      localStorage.setItem('currentPlatform', platformForCurrentRoute);
      setCurrentPlatform(platformForCurrentRoute);
    }
  }, [location.pathname, currentPlatform]);

  useEffect(() => {
    updateTheme(currentPlatform);
  }, [currentPlatform]);

  const value = {
    currentPlatform,
    switchPlatform,
  }

  return (
    <PlatformContext.Provider value={value}>
      {children}
      <PlatformTransition />
    </PlatformContext.Provider>
  );
}

export function usePlatform() {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error('usePlatform must be used within a PlatformProvider');
  }
  return context;
}


// import { createContext, useState, useEffect, useContext, useMemo } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { legendiniRoutes, quiziniRoutes } from './data/PlatformRoutes';
// import PlatformTransition from './PlatformTransition';

// const PlatformContext = createContext();

// export function PlatformProvider({ children }) {
//   const [currentPlatform, setCurrentPlatform] = useState(() =>
//     localStorage.getItem('currentPlatform') || 'quizini'
//   );
//   const [isTransitioning, setIsTransitioning] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();

//   console.log(localStorage.getItem('currentPlatform'));
//   console.log('PlatformProvider rendered ', currentPlatform);

//   // navigate("/");


//   useEffect(() => {
//     const isLegendiniRoute = legendiniRoutes.some(route => location.pathname.includes(route));
//     const isQuiziniRoute = quiziniRoutes.some(route => location.pathname.includes(route));

//     const newPlatform = isLegendiniRoute ? 'legendini' :
//                        isQuiziniRoute ? 'quizini' :
//                        currentPlatform;

//     if (newPlatform !== currentPlatform) {
//       setIsTransitioning(true);
//       setCurrentPlatform(newPlatform);
//       localStorage.setItem('currentPlatform', newPlatform);

//       // const color = newPlatform === 'quizini' ? 'purple' : 'green';
//       // document.documentElement.dataset.theme = color;
      
//       // Reset transition state after animation completes
//       setTimeout(() => {
//         setIsTransitioning(false);
//       }, 1000); // Adjust this timing to match your animation duration
//     }
//   }, [location.pathname, currentPlatform]);

//   const updateTheme = (platform) => {
//     const themecolor = platform === 'quizini' ? 'purple' : 'green';
//     if (!themecolor) return;
//     document.documentElement.dataset.theme = themecolor;
//   };
//   useEffect(() => {
//     updateTheme(currentPlatform);
//   }, [currentPlatform]);

//   const value = useMemo(() => ({
//     currentPlatform,
//     setCurrentPlatform,
//     isTransitioning
//   }), [currentPlatform, isTransitioning]);

//   return (
//     <PlatformContext.Provider value={value}>
//       {children}
//       <PlatformTransition />
//     </PlatformContext.Provider>
//   );
// }

// export function usePlatform() {
//   const context = useContext(PlatformContext);
//   if (!context) {
//     throw new Error('usePlatform must be used within a PlatformProvider');
//   }
//   return context;
// }
