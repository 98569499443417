import { BadgeButton } from 'components/badge';

export default function ListTags({ tags, context = 'quiz' }) {
  if (!tags) {
    return null;
  }

  const url = context === 'quiz' ? '/tags/' : '/storytags/';

  return (
    <div className={`mb-2`}>
      <div className="flex flex-wrap gap-1">
        {tags.map((tag, index) => (
          <div key={index}>
            <BadgeButton href={url + tag.name}>{tag.name}</BadgeButton>
          </div>
        ))}
      </div>
    </div>
  );
}
