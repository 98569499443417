
import {
  ExclamationTriangleIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid'
import {
  RectangleStackIcon,
  PlayIcon as Play,
  UserGroupIcon,
  CheckBadgeIcon,
  TrophyIcon,
  DocumentTextIcon,
} from '@heroicons/react/20/solid'

export const quiziniRoutes = [
  '/quiz/',
  '/quizzes/',
  '/rewards/',
  '/lists/',
];

export const legendiniRoutes = [
  '/stories/', 
  '/legendini', 
  '/story/',
  '/storyeditor'
];

export const sharedRoutes = [
  '/messages',
  '/notifications',
  '/settings',
  '/profile',
  '/user/',
  '/contact',
  '/privacy',
  '/updates',
  '/feed',
  '/leaderboard'
];

export const routeMappings = {
  quizini: {
    '/legendini': '/',
    '/stories/search': '/search',
    '/stories/latest': '/latest',
    '/stories/popular': '/popular',
    '/mystories': '/overview',
  },
  legendini: {
    '/': '/legendini',
    '/search': '/stories/search',
    '/latest': '/stories/latest',
    '/popular': '/stories/popular',
    '/overview': '/mystories',
  }
};

export const adminRoutes = [
  { name: 'Dashboard', href: '/admin/', icon: HomeIcon, current: false },
  { name: 'Quizzes', href: '/admin/quizzes', icon: Play, current: false },
  { name: 'Stories', href: '/admin/stories', icon: DocumentTextIcon, current: false },
  { name: 'Badges', href: '/admin/badges', icon: CheckBadgeIcon, current: false },
  { name: 'Challenges', href: '/admin/challenges', icon: TrophyIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserGroupIcon, current: false },
  { name: 'Collection', href: '/admin/collectionimages', icon: SparklesIcon, current: false },
  { name: 'Feedback', href: '/admin/feedback', icon: RectangleStackIcon, current: false },
  { name: 'Question Lib', href: '/admin/questionlib', icon: QuestionMarkCircleIcon, current: false },
  { name: 'Actions', href: '/admin/actions', icon: ExclamationTriangleIcon, current: false },
]