import { createContext, useState, useEffect } from "react";
import useAuth from "./hooks/useAuth.js";
import levels from "./levels.js";
import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

const ProgressContext = createContext({});

export const ProgressProvider = ({ children }) => {
    const [progress, setProgress] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    useEffect(() => {
        let points = 0;
        let level = 1;
        if(auth?.user){
            points = auth?.user?.points ? auth?.user?.points : 0;
            level = auth?.user?.level ? auth?.user?.level : 1;
        }

        setProgress({
            level: level,
            points: points,
            progress: calculateProgress(level, points), // In %
            isLevelUp: false
        })
    }, [auth]);

    function calculateProgress(currentUserlevel, currentUserPoints){
        let currentLevelPoints = levels[currentUserlevel-1].points;
        let nextLevelPoints = levels[currentUserlevel].points;
        let currentProgress = (currentUserPoints / nextLevelPoints) * 100;
        let result = {
            userLevelPoints: currentLevelPoints,
            nextLevelPoints: nextLevelPoints,
            currentProgress: currentProgress
        }
        return result
    }

    function closeLevelInfo(){
        setProgress({
            ...progress,
            isLevelUp: false
        });
    }

    function updateUserPoints(action){
        let newPoints = 0;
        switch(action){
            case "quiz_play":
                newPoints = progress.points + 10;
                break;
            case "comment":
                newPoints = progress.points + 1;
                break;
            default:
                break;
        }
        axiosPrivate.post("/api/progress/update", {
            action: action
        })
        .then((res) => {
            if(res.data.success){
                console.log(res.data);
            }
        });
        // Check if users is reaching a new level
        const currentLevel = levels.findIndex(level => level.level === progress.level);
        const nextLevel = levels[currentLevel + 1];
        if(newPoints >= nextLevel.points){
            setProgress({
                ...progress,
                points: newPoints,
                level: nextLevel.level,
                progress: calculateProgress(nextLevel.level, newPoints),
                isLevelUp: true
            });
        } else {
            setProgress({
                ...progress,
                progress: calculateProgress(progress.level, newPoints),
                points: newPoints
            });
        }
    }

    return (
        <ProgressContext.Provider value={{ progress, updateUserPoints, closeLevelInfo }}>
            {children}
        </ProgressContext.Provider>
    )
}

export default ProgressContext;