import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import useAuth from './hooks/useAuth';

// Create the context
const ConversationsContext = createContext();

// Custom hook to use the context
export const useConversations = () => {
  const context = useContext(ConversationsContext);
  if (!context) {
    throw new Error('useUnreadConversations must be used within an ConversationsProvider');
  }
  return context;
};

// Provider component
export const ConversationsProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();


  const { data: conversations = [], isLoading } = useQuery({
    queryKey: ['conversations'],
    queryFn: async () => {
      const res = await axiosPrivate.post('/api/conversation/getLatest', { limit: 20 });
      return res.data.map(conversation => ({
        ...conversation,
        participants: conversation.participants,
        fileredParticipants: conversation.participants.filter(participant => participant._id !== auth.user._id)
      }));
    },
    enabled: !!auth.user,
    staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    cacheTime: 60 * 60 * 1000, // 1 hour in milliseconds
  });


  const markAsReadMutation = useMutation({
    mutationFn: (conversationId) => axiosPrivate.post('/api/conversation/markAsRead', { conversationId }),
    onSuccess: (res) => {
      queryClient.setQueryData(['conversations'], (oldData) => {
        const conversationId = res.data.conversation?._id;
        return oldData.map((conversation) => {
          if (conversation._id === conversationId) {
            return {
              ...conversation,
              lastMessageReadBy: [auth.user._id],
            };
          }
          return conversation;
        });
      });
    },
    onError: (err) => console.log(err),
  });

  const updateConversationWithNewMessage = useCallback((conversationId, newMessage, lastMessageReadBy) => {
    queryClient.setQueryData(['conversations'], (oldData) => {
      if (!oldData) return oldData;
  
      const existingConversation = oldData.find(conversation => conversation._id === conversationId);
  
      if (existingConversation) {
        const newConversation = {
          ...existingConversation,
          lastMessage: newMessage,
          lastMessageReadBy: lastMessageReadBy,
        };
  
        const filteredConversations = oldData.filter(conversation => conversation._id !== conversationId);
  
        return [newConversation, ...filteredConversations];
      } else {
        // Refetch all conversations if the conversation is not found
        queryClient.invalidateQueries(['conversations']);
        return oldData;
      }
    });
  }, [queryClient]);

  const markConversationAsRead = (conversationId) => {
    markAsReadMutation.mutate(conversationId);
  };

  // Helper function to check if a conversation is unread
  const isConversationUnread = (conversation) => {
    if (!conversation.lastMessage) return false;
    if (conversation.lastMessage?.author?._id === auth.user?._id) return false;
    return !conversation.lastMessageReadBy?.includes(auth.user?._id);
  };

  // Memoized value for hasUnreadMessages
  const hasUnreadMessages = useMemo(() => {
    return conversations.some(isConversationUnread);
  }, [conversations]);

  const value = {
    conversations,
    isLoading,
    markConversationAsRead,
    markAsReadMutation,
    hasUnreadMessages,
    updateConversationWithNewMessage,
  };

  return (
    <ConversationsContext.Provider value={value}>
      {children}
    </ConversationsContext.Provider>
  );
};