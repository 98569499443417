import { forwardRef } from 'react'

function clsx(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OuterContainer = forwardRef(function OuterContainer(
  { className, children, ...props },
  ref
) {
  return (
    <div ref={ref} className={clsx('', className)} {...props}>
      <div className="mx-auto max-w-7xl h-full">{children}</div>
    </div>
  )
})

const InnerContainer = forwardRef(function InnerContainer(
  { className, children, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      // className={clsx('relative px-4', className)}
      className={clsx(!className && 'relative px-4', className)}
      {...props}
    >
      <div className="mx-auto h-full">{children}</div>
    </div>
  )
})

export const Container = forwardRef(function Container(
  { children, innerClassName, ...props },
  ref
) {
  return (
    <OuterContainer ref={ref} {...props}>
      <InnerContainer className={innerClassName}>{children}</InnerContainer>
    </OuterContainer>
  )
})

Container.Outer = OuterContainer
Container.Inner = InnerContainer
