import { memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { usePlatform } from 'utils/PlatformProvider'
import QuiziniLogo from "assets/img/Quizini-Logo.svg";
import LegendiniLogo from "assets/img/Legendini.svg";

const PlatformLogo = memo(function PlatformLogo() {
  const { currentPlatform } = usePlatform();

  return (
    <Link to={currentPlatform === 'legendini' ? '/legendini' : '/'}>
      <motion.img
        key={currentPlatform}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.3 }}
        className={`w-auto ${currentPlatform === 'quizini' ? 'h-8' : 'h-10'}`}
        src={currentPlatform === 'quizini' ? QuiziniLogo : LegendiniLogo}
        alt={`${currentPlatform === 'quizini' ? 'Quizini' : 'Legendini'} Logo`}
      />
    </Link>
  );
});

export default PlatformLogo;